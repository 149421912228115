export const settings = {
    dbconfig: {
        khemrastorecom: {
            dbname: 'pfk',
            dflang: 'en',
            telegram: {
                token: '2002314963:AAEoB6jJYYtchYDSS4XwVxCYMNs5UmbPAgU',
                chat_id: '-537308182'
            }
        },
        farmkhmercom: {
            dbname: 'pfk',
            dflang: 'kh',
            telegram: {
                token: '2002314963:AAEoB6jJYYtchYDSS4XwVxCYMNs5UmbPAgU',
                chat_id: '-537308182'
            }
        },
        dakcharmcom: {
            dbname: 'dakcharm',
            dflang: 'en',
            telegram: {
                token: '5482881200:AAFkpI-pa7DwSlhIp_DudZ-twywIiCsRn8A',
                chat_id: '-1001679383827'
            }
        },
        localhost: {
            dbname: 'pfk',
            dflang: 'en',
            telegram: {
                token: '2020116391:AAFMvag6GHxlR4J1VkyqjZ-IcBHRTM-nyyg',
            chat_id: '-509261422'
            }
        }
    },
    dbusing: {
        dbname: 'pfk',
        dflang: 'en',
        telegram: {
            token: '2002314963:AAEoB6jJYYtchYDSS4XwVxCYMNs5UmbPAgU',
            chat_id: '-537308182'
        }
    },
    apiurl: 'https://admin.apanak.com',
}