<template>
  <div class="page">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
  </div>
</template>
<script>
export default {
  name: "Loading",
  data() {
    return {
      appjs: window.appjs,
    };
  },
  async mounted() {

    const internet = await this.appjs.checkOnlineStatus();
    if (internet) {
      await this.$store.dispatch("restApiGetLang");
      await this.$store.dispatch("syncData");
      await this.$store.dispatch("initData");
      //this.$router.push({path:'/home'});
      this?.$router?.replace({ path: "/home" });
    }
  },

};
</script>

<style scoped>
.page {
  position: absolute;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  top: 10%;
}
</style>
